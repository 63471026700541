<template>
  <b-modal
    id="purchase"
    size="lg"
    @show="initData"
    hide-header
    v-model="show"
    title="Purchase Filters"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-card class="mb-0">
      <b-alert
        variant="danger"
        dismissible
        :show="hasError"
        @dismissed="resetErrorMessage()"
        >{{ errorMessage }}</b-alert
      >

      <div class="modal-content">
        <b-card-header header-class="vendor-card">
          <h5>
            <b>{{ modalTitle() }}</b>
          </h5>
        </b-card-header>
        <b-card-body>
          <b-list-group v-if="summaryStage" class="no-border">
            <b-list-group-item>
              <b-row>
                <b-col cols="4" class="strong">Order Type:</b-col>
                <b-col cols="8">FI Prospect List</b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col cols="4" class="strong">Number of FIs:</b-col>
                <b-col cols="8">{{ totalRecords | numberFormat }}</b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col cols="4" class="strong">Delivery Method:</b-col>
                <b-col cols="8">CSV / Excel</b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item v-if="tokens !== 0">
              <b-row>
                <b-col cols="4" class="strong">Token Used:</b-col>
                <b-col cols="8"
                  >{{ tokensBeingUsed | numberFormat }} of
                  {{ tokens | numberFormat }} total</b-col
                >
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row class="pt-2 strong">
                <b-col cols="4">Total Cost:</b-col>
                <b-col cols="8">{{ amount | currencyFormat }}</b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row class="pt-3">
                <b-col cols="12" class="text-center">
                  <b-button
                    size
                    variant="secondary"
                    class="fw-btn mx-1"
                    @click="closeModal"
                    >Cancel</b-button
                  >
                  <b-button
                    size
                    variant="primary"
                    class="fw-btn mx-1"
                    :disabled="proceedButtonDisabled"
                    @click="createOrder"
                    >Proceed</b-button
                  >
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <b-list-group v-else-if="paymentStage">
            <b-list-group-item>
              <b-row>
                <b-col sm="8">
                  <b>
                    <i>Total Financial Institutions:</i>
                  </b>
                </b-col>
                <b-col sm="4" class="text-right">
                  <b>{{ totalRecords | numberFormat }}</b>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col sm="8">
                  <b>
                    <i>Total Price:</i>
                  </b>
                </b-col>
                <b-col sm="4" class="text-right">
                  <b>{{ amount | currencyFormat }}</b>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <div v-if="this.clientToken">
                <DropIn
                  wrapperClass="p-3"
                  :amount="amount"
                  :authToken="clientToken"
                  :collectCardHolderName="true"
                  :enableDataCollector="true"
                  :enablePayPal="false"
                  @btError="handleError"
                  @closeModal="closeModal"
                  @btSuccess="handleSuccess"
                ></DropIn>
              </div>
              <div v-else>
                <content-placeholders>
                  <content-placeholders-heading :img="true" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-list-group v-else-if="confirmationStage" class="no-border">
            Thank you for your order. {{ downloadDisplayMessage }}
            <b-list-group-item class="pt-3 text-left">
              <b-row>
                <b-col cols="4" offset="2">
                  <div>
                    <b
                      >Download Requested By<span class="float-right"
                        >:</span
                      ></b
                    >
                  </div></b-col
                >
                <b-col cols="6">
                  <div>{{ userFullName }}</div></b-col
                >
              </b-row>
              <b-row>
                <b-col cols="4" offset="2">
                  <b
                    ><div>Email<span class="float-right">:</span></div></b
                  ></b-col
                >
                <b-col cols="6">
                  <div>{{ currentUserEmail }}</div></b-col
                >
              </b-row>
              <b-row>
                <b-col cols="4" offset="2">
                  <b>
                    <div>Date/Time<span class="float-right">:</span></div></b
                  ></b-col
                >
                <b-col cols="6">
                  <div>{{ currentDateTime }}</div></b-col
                >
              </b-row>
              <b-row>
                <b-col cols="4" offset="2">
                  <b>
                    <div>IP Address<span class="float-right">:</span></div></b
                  ></b-col
                >
                <b-col cols="6">
                  <div>{{ ip }}</div></b-col
                >
              </b-row>
              <b-row>
                <b-col cols="4" offset="2">
                  <b
                    ><div>Location<span class="float-right">:</span></div></b
                  ></b-col
                >
                <b-col cols="6">
                  <div>{{ location }}</div></b-col
                >
              </b-row>
              <b-row>
                <b-col cols="4" offset="2">
                  <b
                    ><div>Browser<span class="float-right">:</span></div></b
                  ></b-col
                >
                <b-col cols="6">
                  <div>{{ browserInfo() }}</div></b-col
                >
              </b-row>
            </b-list-group-item>
            <b-card class="std-border mt-3 mb-2" v-if="contactElement">
              <template #header
                ><h6 class="mb-0">Download Options:</h6></template
              >
              <div class="row">
                <div class="col-sm-12 mb-2">
                  <div class="row">
                    <div class="col-sm-6">
                      <h6 class="mb-0 lh-dot-90">
                        Single File
                      </h6>
                      <small class="text-muted">
                        (FI Data and Contact are together in one file)
                      </small>
                    </div>
                    <div class="col-sm-3">
                      <b-form-select v-model="combinedFileType" class="mb-3">
                        <b-form-select-option
                          value="csv"
                          v-if="isCsvDownloadAllowed"
                          >CSV</b-form-select-option
                        >
                        <b-form-select-option
                          value="xlsx"
                          v-if="isXlsxDownloadAllowed"
                          >Excel</b-form-select-option
                        >
                      </b-form-select>
                    </div>
                    <div class="col-sm-3 text-right">
                      <b-button
                        size
                        variant="primary"
                        class="mx-2"
                        :href="combinedFileURL"
                        target="_blank"
                        ref="download0"
                        :disabled="combinedFileDownloadDisabled"
                        @click="disableDownload(['0', '1', '2'], $event)"
                        >{{
                          downloadable ? 'Download' : 'Send Email'
                        }}</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 mb-2">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6 class="mb-0 lh-dot-90">
                        Two File
                      </h6>
                      <small class="text-muted">
                        (FI Data and Contact are in separate file)
                      </small>

                      <div class="row mt-2 mb-3 align-items-center">
                        <div class="col-sm-9">
                          <div class="row align-items-baseline">
                            <div class="col-sm-6">
                              <h6 class="pl-3 ">Financial Institutions File</h6>
                            </div>
                            <div class="col-sm-2"></div>
                            <div class="col-sm-4">
                              <b-form-select v-model="fiFileType" class="">
                                <b-form-select-option
                                  value="csv"
                                  v-if="isCsvDownloadAllowed"
                                  >CSV</b-form-select-option
                                >
                                <b-form-select-option
                                  value="xlsx"
                                  v-if="isXlsxDownloadAllowed"
                                  >Excel</b-form-select-option
                                >
                              </b-form-select>
                            </div>
                          </div>
                          <div class="row align-items-baseline">
                            <div class="col-sm-6 pt-2">
                              <h6 class="pl-3">Contacts File</h6>
                            </div>
                            <div class="col-sm-2"></div>
                            <div class="col-sm-4 pt-2">
                              <b-form-select v-model="contactFileType" class="">
                                <b-form-select-option
                                  value="csv"
                                  v-if="isCsvDownloadAllowed"
                                  >CSV</b-form-select-option
                                >
                                <b-form-select-option
                                  value="xlsx"
                                  v-if="isXlsxDownloadAllowed"
                                  >Excel</b-form-select-option
                                >
                              </b-form-select>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-3 text-right">
                          <div v-if="!downloadable">
                            <b-button
                              size
                              variant="primary"
                              class="mx-2"
                              :href="this.downloadUrl"
                              target="_blank"
                              ref="download1"
                              :disabled="fiFileDownloadDisabled"
                              @click="sendEmailWithTwoLinks($event)"
                            >
                              Send Email
                            </b-button>
                            <!-- disableDownload(['1', '0'], $event)" -->
                          </div>
                          <div v-else>
                            <div>
                              <b-button
                                size
                                variant="primary"
                                class="mx-2"
                                :href="fiFileURL"
                                target="_blank"
                                ref="download1"
                                :disabled="fiFileDownloadDisabled"
                                @click="disableDownload(['1', '0'], $event)"
                              >
                                Download
                              </b-button>
                            </div>
                            <div class="pt-2">
                              <b-button
                                size
                                variant="primary"
                                class="mx-2"
                                :href="contactFileURL"
                                target="_blank"
                                ref="download2"
                                :disabled="contactFileDownloadDisabled"
                                @click="disableDownload(['2', '0'], $event)"
                              >
                                Download
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>

            <div v-else>
              <b-list-group-item class="pt-3 text-center">
                <b-row class="justify-content-center">
                  <b-col cols="12">
                    <b v-if="downloadable">Click here to download your file:</b>
                    <b v-else>Click here to select your file type:</b>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item class="pt-3 text-center">
                <b-row>
                  <b-col cols="12">
                    <b-button
                      size
                      variant="primary"
                      class="fw-btn mx-2"
                      :href="formatStrippedURL"
                      target="_blank"
                      ref="download3"
                      v-if="isCsvDownloadAllowed"
                      :disabled="combinedFileDownloadDisabled"
                      @click="disableDownload(['3', '4'], $event)"
                      >{{
                        downloadable ? 'Download CSV' : 'CSV File'
                      }}</b-button
                    >
                    <b-button
                      size
                      variant="primary"
                      class="fw-btn mx-2"
                      :href="downloadUrl"
                      target="_blank"
                      ref="download4"
                      v-if="isXlsxDownloadAllowed"
                      :disabled="combinedFileDownloadDisabled"
                      @click="disableDownload(['4', '3'], $event)"
                      >{{
                        downloadable ? 'Download Excel' : 'Excel File'
                      }}</b-button
                    >
                  </b-col>
                </b-row>
              </b-list-group-item>
            </div>
            <b-list-group-item class="p-0 pt-3 text-center">
              <b-row>
                <b-col cols="12">
                  <P class="text-justify">
                    <b>NOTICE:</b> The data you are downloading is owned by FI
                    Navigator Corporation. Any data, files, or documents
                    downloaded or obtained from FI Navigator is a component of
                    the Licensee's Licensed Materials and deemed as Confidential
                    Information. Licensee may only distribute Licensed Materials
                    internally, either in original or modified form. Any other
                    form of distribution is not permitted without the prior
                    written consent of, and with express attribution to, FI
                    Navigator Corporation. Unauthorized distribution of
                    copyrighted material by any intentional or unintentional
                    recipient is subject to civil and criminal liabilities.
                  </P>
                  <P class="text-justify">
                    FI Navigator has embedded data fingerprinting unique to each
                    data export and components of the data itself to source any
                    unauthorized distribution.
                  </P>
                </b-col></b-row
              >
            </b-list-group-item>
            <b-list-group-item class="pt-3 text-center">
              <b-row>
                <b-col cols="12">
                  <b-button
                    size
                    variant="secondary"
                    class="fw-btn"
                    @click="closeModal"
                    >Close</b-button
                  >
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card-body>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
// global
import { mapState, mapMutations, mapGetters } from 'vuex'
// ui
import DropIn from '@/views/brainTree/dropIn'
// utilities
import { BTErrorMessage } from '@/utilities'
import * as moment from 'moment'

export default {
  name: 'Order',
  props: [
    'query',
    'show',
    'totalRecords',
    'amount',
    'encryptedPrice',
    'tokens',
    'tokensBeingUsed'
  ],
  components: {
    DropIn
  },
  data() {
    return {
      stage: null,
      errorMessage: null,
      clientToken: null,
      orderId: null,
      downloadUrl: null,
      fiFileType: 'csv',
      contactFileType: 'csv',
      combinedFileType: 'csv',
      combinedFileDownloadDisabled: false,
      fiFileDownloadDisabled: false,
      contactFileDownloadDisabled: false,
      downloadable: true,
      proceedButtonDisabled: false,
      userFullName: '',
      location: '',
      ip: ''
    }
  },
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapGetters('AdvancedQuery', [
      'isCsvDownloadAllowed',
      'isXlsxDownloadAllowed',
      'isMortgageLender'
    ]),
    ...mapState('AdvancedQuery', {
      finQuery: state => state.finQuery
    }),
    ...mapState('ReportWriter', {
      reportElements: state => state.reportElements
    }),
    summaryStage: function() {
      return this.stage === 'summary'
    },
    paymentStage: function() {
      return this.stage === 'payment'
    },
    confirmationStage: function() {
      return this.stage === 'confirmation'
    },
    hasError: function() {
      return this.errorMessage !== null
    },
    formatStrippedURL() {
      if (!this.downloadUrl) {
        return null
      }

      return this.downloadUrl.replace('download.xlsx', 'download')
    },
    combinedFileURL() {
      if (!this.downloadUrl) {
        null
      }

      let url =
        this.combinedFileType === 'csv'
          ? this.formatStrippedURL
          : this.downloadUrl

      return `${url}?file=single`
    },
    fiFileURL() {
      if (!this.downloadUrl) {
        null
      }

      let url =
        this.fiFileType === 'csv' ? this.formatStrippedURL : this.downloadUrl

      return `${url}?file=fi`
    },
    contactFileURL() {
      if (!this.downloadUrl) {
        null
      }

      let url =
        this.contactFileType === 'csv'
          ? this.formatStrippedURL
          : this.downloadUrl

      return `${url}?file=contact`
    },
    contactElement() {
      return this.reportElements.find(el => el.type === 'fi_contacts')
    },
    downloadDisplayMessage() {
      if (this.downloadable)
        return 'You may download your data using the link below. You will also receive an email with a download link.'
      else
        return 'An email will be sent to you with a link to download your file. Select the file format below.'
    },
    currentDateTime() {
      const now = new Date()
      return moment(now).format('MMM DD, YYYY, h:mm A')
    }
  },
  methods: {
    ...mapMutations('AdvancedQuery', ['setTokens']),
    browserInfo() {
      let userAgent = window.navigator.userAgent
      let match =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || []
      let temp
      if (/trident/i.test(match[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(userAgent) || []

        return `IE ${temp[1] || ''}`
      }

      if (match[1] === 'Chrome') {
        temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/)

        if (temp !== null) {
          return temp
            .slice(1)
            .join(' ')
            .replace('OPR', 'Opera')
        }

        temp = userAgent.match(/\b(Edg)\/(\d+)/)

        if (temp !== null) {
          return temp
            .slice(1)
            .join(' ')
            .replace('Edg', 'Edge (Chromium)')
        }
      }

      match = match[2]
        ? [match[1], match[2]]
        : [navigator.appName, navigator.appVersion, '-?']
      // temp = userAgent.match(/version\/(\d+)/i)

      // if (temp !== null) {
      //   match.splice(1, 1, temp[1])
      // }

      return match.join(' ')
    },
    getLocation(ip) {
      let loc = '-'
      this.$http
        .get('https://ipapi.co/' + ip + '/json/', {
          withCredentials: false,
          handleErrors: true
        })
        .then(res => {
          if (res.data.city) loc = res.data.city + ', '
          if (res.data.region) loc = loc + res.data.region + ', '
          if (res.data.country) loc = loc + res.data.country
          this.location = loc
        })
    },
    initData() {
      this.stage = 'summary'
    },
    modalTitle: function() {
      if (this.summaryStage) {
        return 'Order Details'
      } else if (this.paymentStage) {
        return 'Order Summary'
      } else if (this.confirmationStage) {
        return 'Order Confirmation - Thank You!'
      }
    },
    minimumOrder: function() {
      return this.totalRecords < 500
    },
    closeModal: function() {
      this.stage = 'summary'
      this.$emit('closeModal')
    },
    createOrder: function() {
      this.proceedButtonDisabled = true
      this.$http
        .post(
          '/api/checkouts',
          {
            query: JSON.stringify(this.finQuery),
            record_count: this.totalRecords,
            amount: this.amount,
            encrypted_price: this.encryptedPrice,
            is_mbc: this.isMortgageLender
          },
          {
            handleErrors: true
          }
        )
        .then(res => {
          this.proceedButtonDisabled = false
          this.ip = res.data.ip
          this.userFullName = res.data.user_full_name
          this.getLocation(res.data.ip)
          if (res.data.ordered) {
            this.downloadUrl = res.data.url
            this.stage = 'confirmation'
            this.enableDownloads()
            this.downloadable = res.data.downloadable
          } else {
            this.clientToken = res.data.client_token
            this.orderId = res.data.order_id
            this.stage = 'payment'
          }
        })
    },
    handleError: function(msg) {
      this.errorMessage = msg
    },
    handleSuccess: function(payload) {
      this.errorMessage = null
      let data = new window.FormData()
      data.append('order_id', this.orderId)
      data.append('payment_method_nonce', payload.nonce)
      this.$http
        .post('/api/checkouts/complete', data, {
          handleErrors: true
        })
        .then(res => {
          if (res.data.order_success) {
            this.downloadUrl = res.data.url
            this.stage = 'confirmation'
            this.setTokens(res.data.tokens)
            this.enableDownloads()
            this.downloadable = res.data.downloadable
          } else {
            this.closeModal()
            this.$toasted.show(BTErrorMessage, {
              icon: 'chain-broken',
              type: 'error'
            })
          }
        })
    },
    enableDownloads() {
      this.combinedFileDownloadDisabled = false
      this.fiFileDownloadDisabled = false
      this.contactFileDownloadDisabled = false
    },
    disableDownload(buttonList, event) {
      const downloadMessage = this.downloadable
        ? 'Your download file is being created...'
        : 'Large File: Your download file will be delivered in email.'
      if (!this.downloadable) {
        event.target.attributes.target.nodeValue = '_self'
        const url = event.target.attributes.href.nodeValue
        event.target.attributes.href.nodeValue = '#'
        this.$http.get(url, {
          handleErrors: true
        })
      }
      this.$toasted.show(downloadMessage, {
        icon: 'user-circle',
        type: 'success'
      })
      buttonList.forEach(button => {
        if (['0', '3', '4'].includes(button))
          this.combinedFileDownloadDisabled = true
        else if (button === '1') this.fiFileDownloadDisabled = true
        else if (button === '2') this.contactFileDownloadDisabled = true
      })
    },
    resetErrorMessage() {
      this.errorMessage = null
    },
    sendEmailWithTwoLinks(event) {
      let url = `${this.downloadUrl}?multifile=true&fi=${this.fiFileType}&contact=${this.contactFileType}`
      event.target.attributes.href.nodeValue = url
      this.disableDownload(['1', '0'], event)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  border: 1px solid #d1d4d7;
}

.no-border {
  .list-group-item {
    border: none;
  }
}
.strong {
  font-weight: bold;
}
.loading {
  opacity: 0.5;
  pointer-events: none;
}
</style>
